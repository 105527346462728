import React, { useContext } from "react";
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { Paper, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { HydromodelsContext } from "../../contexts/HydromodelsProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  icon: {
    minWidth: 30,
  },
}));

/**
 * Component used to display a list of key gages
 * associated with the current model
 */
const KeyGagedFlows = (props) => {
  const classes = useStyles();
  const context = useContext(HydromodelsContext);
  const { gages } = context;
  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Key Gaged Flows</Typography>
      <List dense>
        {gages.map((gage) => (
          <ListItem key={gage.title}>
            <ListItemIcon className={classes.icon}>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary={gage.title} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default KeyGagedFlows;
