import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import TopNav from "../components/TopNav";
import PageTitle from "../components/PageTitle";
import { HydromodelsContext } from "../contexts/HydromodelsProvider";
import ModelGraph from "../components/ModelGraph";
import PrecipGraph from "../components/PrecipGraph";
import Map from "../components/Map";
import ModelInfo from "../components/ModelInfo";
import ModelInputs from "../components/ModelInputs";
import KeyGagedFlows from "../components/KeyGagedFlows";
import ModelSchematic from "../components/ModelSchematic";
import { useRef } from "react";
import { useContext } from "react";
import HistoricalModelGraph from "../components/HistoricalModelGraph";

// create page styles
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
  },
  content: {
    maxWidth: 1200,
    margin: `30px auto`,
  },
  gridContainer: {
    marginTop: theme.spacing(2),
  },
  map: {
    height: "100%",
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Hydromodels = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const context = useContext(HydromodelsContext);
  const schematicRef = useRef(null);

  const handleScroll = () => {
    scrollToRef(schematicRef);
  };

  const links = [
    { title: "Boulder Creek", path: "boulder-creek" },
    { title: "Cherry Creek", path: "cherry-creek" },
    { title: "Goldsmith Gulch", path: "goldsmith-gulch" },
    { title: "Harvard Gulch", path: "harvard-gulch" },
    { title: "Lena Gulch", path: "lena-gulch" },
  ];

  return (
    <div className={classes.root}>
      <TopNav history={history} links={links} />
      <div className={classes.content}>
        <PageTitle />
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} sm={4}>
            <Map />
          </Grid>
          <Grid item xs={12} sm={8}>
            {context.viewerType === "real-time" && <ModelGraph />}
            {context.viewerType === "historical" && <HistoricalModelGraph />}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <PrecipGraph />
              </Grid>
              <Grid item xs={12} sm={4}>
                <KeyGagedFlows />
              </Grid>
            </Grid>
          </Grid>
          <Box marginLeft={1} marginRight={1} marginTop={3} marginBottom={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <ModelInfo handleScroll={handleScroll} />
              </Grid>
              <Grid item xs={12} sm={8}>
                <ModelInputs />
              </Grid>

              <ModelSchematic schematicRef={schematicRef} />
            </Grid>
          </Box>
        </Grid>
      </div>
    </div>
  );
};

export default Hydromodels;
