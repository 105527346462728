import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import App from "./App";

import ErrorBoundary from "./components/ErrorBoundary";
import * as serviceWorker from "./serviceWorker";

// Configure error tracking with Sentry
Sentry.init({
  dsn: "https://1e25d02e9a7e44dbae247292a9afd82a@sentry.io/1819185",
  environment: process.env.REACT_APP_ENVIRONMENT,
});

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
