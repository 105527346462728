import React from "react";
import { Typography } from "@material-ui/core";

const SectionText = ({ children }) => (
  <Typography variant="body1" color="textSecondary" paragraph>
    {children}
  </Typography>
);

export default SectionText;
