import React, { useContext } from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { HydromodelsContext } from "../../contexts/HydromodelsProvider";
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Line,
  Legend,
  Tooltip,
} from "recharts";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tooltip: {
    width: 200,
    backgroundColor: "#252526",
    color: "#ffffff",
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  legend: {
    "& span": {
      color: "#ffffff",
    },
  },
}));

const CustomTooltip = ({ active, payload }) => {
  const classes = useStyles();

  if (!active) return null;
  return (
    <Paper className={classes.tooltip}>
      <Typography variant="body1">{payload[0].payload.ts_timestamp}</Typography>
      {payload.map((d) => (
        <React.Fragment key={Math.random() * 999999}>
          <Typography variant="body1">
            {d.name}: {d.value}{" "}
            {d.name === "Avg Basinwide Precip (in/hr)" ? "in/hr" : "in"}
          </Typography>
        </React.Fragment>
      ))}
    </Paper>
  );
};

const PrecipGraphRechart = (props) => {
  const context = useContext(HydromodelsContext);
  const classes = useStyles();
  const theme = useTheme();
  const graphData = context.precipData;

  if (graphData.length === 0) return null;
  return (
    <Paper className={classes.paper}>
      <ResponsiveContainer width="100%" height={225}>
        <ComposedChart
          data={graphData}
          margin={{ top: 16, right: 16, left: 8, bottom: 8 }}
        >
          <CartesianGrid
            vertical={false}
            stroke="#9a9595"
            strokeDasharray="3 3"
          />
          <XAxis
            dataKey="ts_timestamp"
            stroke="#c3c3c3"
            // tickFormatter={formatXAxis}
            // ticks={setXAxisTicks(graphData.map((d) => d.ts_timestamp))}
            tickMargin={8}
          />
          <YAxis
            stroke="#c3c3c3"
            // tickFormatter={formatYAxis}
            label={{
              value: "Precip",
              angle: -90,
              fill: "#c3c3c3",
              dx: -25,
            }}
          />
          <Bar
            isAnimationActive={false}
            dataKey="precip"
            barSize={20}
            fill={theme.palette.primary.main}
            name="Avg Basinwide Precip (in/hr)"
          />
          <Line
            isAnimationActive={false}
            dataKey="cum_precip"
            stroke={theme.palette.secondary.main}
            name="Cumulative Basinwide Precip (inches)"
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </ComposedChart>
      </ResponsiveContainer>
      {/* <FlexibleWidthXYPlot
        height={150}
        xType="time"
        onMouseLeave={onMouseLeave}
      >
        <HorizontalGridLines
          style={{
            stroke: "#4D4D4D",
            strokeDasharray: "4 4 4 4",
          }}
        />
        <VerticalBarSeries data={data[1]} color={theme.palette.primary.main} />
        <LineSeries
          data={data[0]}
          color={theme.palette.secondary.main}
          onNearestX={onNearestX}
        />
        <XAxis
          tickTotal={8}
          tickLabelAngle={-45}
          title="Time"
          style={{
            text: { fill: "#CCCCCC" },
            title: { fill: "#CCCCCC" },
          }}
        />
        <YAxis
          title="Precipitation"
          style={{
            text: { fill: "#CCCCCC" },
            title: { fill: "#CCCCCC" },
          }}
        />
        {crosshairValues.length > 0 && (
          <Crosshair values={crosshairValues}>
            <div className={classes.tooltip}>
              {crosshairValues.length > 0 && (
                <Typography variant="body1" gutterBottom>
                  {moment(crosshairValues[0].ts_timestamp_order).format(
                    "hh:mm A"
                  )}
                </Typography>
              )}
              {crosshairValues.map(
                (val) =>
                  val && (
                    <Typography
                      variant="body1"
                      key={Math.random() * 999999}
                      gutterBottom
                    >
                      {val.units
                        ? `5min precip:  ${val.y} ${val.units}.`
                        : `Cumulative:  ${val.y} in.`}
                    </Typography>
                  )
              )}
            </div>
          </Crosshair>
        )}
      </FlexibleWidthXYPlot>
      <DiscreteColorLegend
        className={classes.legend}
        colors={LegendColors}
        orientation="horizontal"
        width={500}
        items={LegendItems}
      /> */}
    </Paper>
  );
};

export default PrecipGraphRechart;
