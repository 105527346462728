import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import SectionTitle from "../SectionTitle";
import SectionText from "../SectionText";
import { HydromodelsContext } from "../../contexts/HydromodelsProvider";

const ModelInfo = ({ handleScroll }) => {
  const context = useContext(HydromodelsContext);
  const {
    modelBackground,
    modelSubBasins,
    modelMethods,
  } = context.modelInformation;

  if (!modelBackground) return null;
  return (
    <>
      <SectionTitle title="Model Background" />
      {modelBackground.map((text, index) => (
        <SectionText key={text.substring(8) + index}>{text}</SectionText>
      ))}
      <SectionTitle title="Methods Used" />
      {modelMethods.map((text, index) => (
        <SectionText key={text.substring(8) + index}>{text}</SectionText>
      ))}
      <SectionTitle title="Model Sub-Basins" />
      {modelSubBasins.map((text, index) => (
        <React.Fragment key={text.substring(8) + index}>
          <SectionText>{text}</SectionText>
          <Button variant="outlined" onClick={handleScroll}>
            View Schematic
          </Button>
        </React.Fragment>
      ))}
    </>
  );
};

export default ModelInfo;
