import React, { useContext } from "react";
import SectionTitle from "../SectionTitle";
import { HydromodelsContext } from "../../contexts/HydromodelsProvider";
import DynamicImage from "../DynamicImage";

const ModelInputs = (props) => {
  const context = useContext(HydromodelsContext);
  const { model } = context.filters;
  const { modelInputs } = context.modelInformation;

  if (!modelInputs) return null;
  return (
    <>
      <SectionTitle title="Model Inputs" />
      {model &&
        modelInputs.map((input) => (
          <DynamicImage key={input} src={`${model.id}/${input}`} alt={input} />
        ))}
    </>
  );
};

export default ModelInputs;
