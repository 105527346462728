const config = {
  "boulder-creek": {
    ndx: 1,
    id: "boulder-creek",
    title: "Boulder Creek Model Viewer",
    zoom: 11,
    center: [-105.336582, 40.030105],
    defaultDesignPoint: 27,
    activeDesignPoint: null,
    gages: [
      { title: "Fourmile Creek at Salinas", alertId: "" },
      { title: "Fourmile Creek Near Crisman at Logan Mill Rd", alertId: "" },
      { title: "Fourmile Creek at SH119", alertId: "" },
      { title: "Boulder Creek at Orodell", alertId: "" },
      { title: "Boulder Creek at 119 Bridge", alertId: "" },
    ],
    modelBackground: [
      "The original Boulder Creek model was created by the United States Army Corp of Engineers (USACE) using HEC-1. The original HEC-1 model used the SCS Curve Number methodology and the Muskingum routing method for the calculations. These methods were accurately reproduced in the spreadsheet version and resulted in identical results for the same design storm. Model output was obtained and the sub-basin unit hydrographs were used directly in the real-time model.",
    ],
    modelSubBasins: [
      "The original USACE HEC-1 model has a total of 15 sub-basins. The real-time hydrologic model has a total of 14 sub-basins. The HEC-1 model had 15 sub-basins, including a basin for modeling Barker Reservoir. However, because Barker Reservoir has sufficient flood capacity, BC- 1 was not included in the real-time model. The original design points specified in the HEC-1 model were used in the real-time model.",
    ],
    modelMethods: [
      "Thiessen Polygon Rain Gage Weighting",
      "SCS Rainfall Runoff Method",
      "Muskingum Routing Channel Routing",
    ],
    modelInputs: ["1A_B.jpg", "1C.jpg"],
    modelSchematic:
      "https://www.lucidchart.com/documents/embeddedchart/2ec712e5-e15c-4da1-a00f-486d0033dc46",
  },
  "cherry-creek": {
    ndx: 2,
    id: "cherry-creek",
    title: "Cherry Creek Model Viewer",
    zoom: 10,
    center: [-104.782238, 39.537004],
    defaultDesignPoint: 19,
    activeDesignPoint: null,
    gages: [
      { title: "at Bayou Gulch", alertId: "" },
      { title: "at Stroh Road", alertId: "" },
      { title: "at Cottonwood Park", alertId: "" },
    ],
    modelBackground: [
      "The original USACE Cherry Creek HEC- 1 model contained 22 sub-basins. The real-time model represents all 22 basins using the original unit hydrographs. The Cherry Creek model uses Green Ampt to estimate the infiltration in the basin which was used in the original model.",
    ],
    modelSubBasins: [
      "Cherry Creek sub-basins were modeled in the Real-time model in the same configuration as the HEC-1 model.",
    ],
    modelMethods: [
      "Thiessen Polygon Rain Gage Weighting",
      "Green Ampt Infiltration Method",
      "Muskingum Routing Channel Routing",
      "Modified Puls Reservoir Routing",
    ],
    modelInputs: ["2a.jpg", "2b.jpg", "2c.jpg", "2d.jpg"],
    modelSchematic:
      "https://www.lucidchart.com/documents/embeddedchart/e23a37a8-e734-45de-b6d5-de3471123d00",
  },
  "goldsmith-gulch": {
    ndx: 3,
    id: "goldsmith-gulch",
    title: "Goldsmith Gulch Model Viewer",
    zoom: 11,
    center: [-104.90249, 39.656815],
    defaultDesignPoint: 13,
    activeDesignPoint: null,
    gages: [
      { title: "at Temple Pond", alertId: "" },
      { title: "at Eastman", alertId: "" },
      { title: "at Iliff Pond", alertId: "" },
    ],
    modelBackground: [
      "James Guo from the Department of Civil Engineering at the University of Colorado originally developed the 16 sub-basin model of Goldsmith Gulch. The original Goldsmith Gulch model was modeled using a Hydro Pond in the early 1990’s. Goldsmith Gulch has eight different reservoirs or detention structures that are represented in the model using Modified Puls reservoir routing.",
    ],
    modelSubBasins: [
      "The real-time Goldsmith Gulch model represents all 16 original sub-basins. GG-14 is an aggregate of sub-basins 14 and 15.",
    ],
    modelMethods: [
      "Thiessen Polygon Rain Gage Weighting",
      "CUHP (Horton Infiltration Method)",
      "Muskingum Routing Channel Routing",
      "Modified Puls Reservoir Routing",
    ],
    modelInputs: ["3a.jpg", "3b.jpg", "3c.jpg", "3d.jpg", "3e.jpg"],
    modelSchematic:
      "https://www.lucidchart.com/documents/embeddedchart/71e90618-c81d-4f8d-8b06-eb111284f124",
  },
  "harvard-gulch": {
    ndx: 4,
    id: "harvard-gulch",
    title: "Harvard Gulch Model Viewer",
    zoom: 12,
    center: [-104.959382, 39.669009],
    defaultDesignPoint: 9,
    activeDesignPoint: null,
    gages: [
      { title: "at Jackson", alertId: "" },
      { title: "at Harvard Gulch Park", alertId: "" },
    ],
    modelBackground: [
      "Ben Urbonas from UDFCD has previously developed a SWMM model for the Harvard Gulch basin using 59 basins and approximately 26 junctions and routing structures. In order to simplify the model, three design points were selected at critical points and the tributary sub-basins were assigned a single raingage for analysis. This resulted in a simplified model with three ‘aggregated’ basins. HG-1 sub-basin was added to the real-time hydromodel to account for basin total effects south of Harvard Gulch.",
      "The unit hydrographs for each basin were developed by adjusting the raingage data in the first time step to produce exactly one inch of runoff volume from the entire aggregated basin. The model computed runoff from this slug of rainfall for each individual sub-basin and routed it through the model to the selected design points. Since the definition of a unit hydrograph is the hydrograph produced by one inch of runoff, the model results at the various design points became the unit hydrograph for that basin in the real-time hydrologic model.",
      "Muskingum routing techniques were used to route the runoff hydrographs downstream. Calibration was performed by comparing the results of the detailed SWMM model at downstream design points. The Muskingum parameters were then optimized in the Excel worksheet to match the SWMM output. The simplified Excel model now mirrors the results from the detailed SWMM model.",
    ],
    modelSubBasins: [
      "Harvard Gulch sub-basins were modeled in the Real-time model in the same configuration as the SWMM model.",
    ],
    modelMethods: [
      "Thiessen Polygon Rain Gage Weighting",
      "CUHP (Horton Infiltration Method)",
      "Muskingum Channel Routing",
    ],
    modelInputs: ["4abc.jpg", "4d.jpg"],
    modelSchematic:
      "https://www.lucidchart.com/documents/embeddedchart/73f7c3f7-8c0f-4a80-b82e-6bf1405654a5",
  },
  "lena-gulch": {
    ndx: 5,
    id: "lena-gulch",
    title: "Lena Gulch Model Viewer",
    zoom: 11,
    center: [-105.147028, 39.74075],
    defaultDesignPoint: 5,
    activeDesignPoint: null,
    gages: [
      { title: "at 6th Ave", alertId: "" },
      { title: "at Nolte Pond Gage", alertId: "" },
      { title: "at Maple Grove Res", alertId: "" },
    ],
    modelBackground: [
      "The original SWMM model for Lega Gulch was developed by Boyle Engineering in 1991. This SWMM model had 79 sub-basins and extended to the inlet of Maple Grove Reservoir. The District also developed a model using their CUHP modeling tool to model Lena Gulch below Maple Grove Reservoir. The CUHP model extends down to the Clear Creek outfall.",
    ],
    modelSubBasins: [
      "The Lena Gulch SWMM and CUHP models have a total of 95 sub-basins that define the hydrology of the watershed. For the simplified real-time model, these were aggregated into 8 sub-basins.",
    ],
    modelMethods: [
      "Thiessen Polygon Rain Gage Weighting",
      "CUHP (Horton Infiltration Method)",
      "Muskingum Routing Channel Routing",
      "Modified Puls Reservoir Routing",
    ],
    modelInputs: ["5ab.jpg", "5c.jpg"],
    modelSchematic:
      "https://www.lucidchart.com/documents/embeddedchart/5cb4def9-3ecb-4b2b-9d2f-d2f343a08887",
  },
};

export default config;
