import React, { useRef, useState, useEffect, useContext } from "react";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { HydromodelsContext } from "../../contexts/HydromodelsProvider";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: 590,
    borderRadius: 4,
  },
}));

const Map = (props) => {
  const classes = useStyles();
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null); // eslint-disable-line
  const context = useContext(HydromodelsContext);
  const { filters, handleChange } = context;

  // TODO prevent map from re-rendering every time the user clicks a point
  useEffect(() => {
    // Declare mapbox map
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/lrewater/ck2qerzon2jb31cp6jpq4tpi7",
      center: filters.model.center,
      zoom: filters.model.zoom,
    });

    // Add a navigation control
    const nav = new mapboxgl.NavigationControl();
    map.addControl(nav, "top-left");

    map.on("click", (e) => {
      const feature = map.queryRenderedFeatures(e.point, {
        layers: ["udfcd-points", "udfcd-points label"],
      })[0];
      if (!feature) return;
      handleChange("designPoint", {
        model: feature.properties.model,
        index: feature.properties.despnt_ndx,
      });
    });
    setMap(map);
  }, [filters.model]); //eslint-disable-line

  return (
    <Paper className={classes.paper}>
      <div id="map" className={classes.map} ref={mapContainer}></div>
    </Paper>
  );
};

export default Map;
