import React, { useContext } from "react";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { HydromodelsContext } from "../../contexts/HydromodelsProvider";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  tooltip: {
    width: 180,
    backgroundColor: "#252526",
    color: "#ffffff",
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  legend: {
    "& span": {
      color: "#ffffff",
    },
  },
}));

const CustomTooltip = ({ active, payload }) => {
  const classes = useStyles();

  if (!active) return null;
  return (
    <Paper className={classes.tooltip}>
      <Typography variant="body1">{payload[0].payload.axistime}</Typography>
      <Typography variant="body1">
        {payload[0].name}: {payload[0].value} cfs
      </Typography>
    </Paper>
  );
};

const ModelGraph = (props) => {
  const context = useContext(HydromodelsContext);
  const classes = useStyles();
  const theme = useTheme();
  const graphData = context.modelOutFlowsData;

  const formatXAxis = (tickItem) => {
    const date = moment(tickItem);
    return date.format("H:mm");
  };

  const formatYAxis = (tickItem) => {
    return tickItem.toLocaleString();
  };

  const setXAxisTicks = (dates) => {
    return [];
  };

  if (graphData.length === 0) return null;
  return (
    <Paper className={classes.paper}>
      <Typography variant="h5" gutterBottom>
        {graphData[0].despnt_name}
      </Typography>
      <ResponsiveContainer width="100%" height={225}>
        <LineChart
          data={graphData}
          margin={{ top: 16, right: 16, left: 0, bottom: 8 }}
        >
          <CartesianGrid
            vertical={false}
            stroke="#9a9595"
            strokeDasharray="3 3"
          />
          <XAxis
            dataKey="ts_timestamp"
            stroke="#c3c3c3"
            tickFormatter={formatXAxis}
            ticks={setXAxisTicks(graphData.map((d) => d.ts_timestamp))}
            tickMargin={8}
          />
          <YAxis
            stroke="#c3c3c3"
            tickFormatter={formatYAxis}
            label={{
              value: "Change in Flow (CFS)",
              angle: -90,
              fill: "#c3c3c3",
              dx: -20,
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line
            isAnimationActive={false}
            type="monotone"
            name="Modeled Flow"
            dataKey="past_modeled_outflow"
            stroke={theme.palette.primary.main}
            dot={false}
            activeDot={{ r: 6 }}
            strokeWidth={2}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            name="Forecasted Flow"
            dataKey="forecasted_outflow"
            stroke={theme.palette.secondary.main}
            dot={{
              r: 2,
              stroke: theme.palette.secondary.main,
              fill: theme.palette.secondary.main,
            }}
            activeDot={{ r: 6 }}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default ModelGraph;
