import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const SectionTitle = ({ title }) => (
  <Typography variant="h5" gutterBottom>
    {title}
  </Typography>
);

SectionTitle.propTypes = {
  title: PropTypes.string,
};

export default SectionTitle;
