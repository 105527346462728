import React, { useContext } from "react";
import SectionTitle from "../SectionTitle";
import { HydromodelsContext } from "../../contexts/HydromodelsProvider";

const ModelSchematic = ({ schematicRef }) => {
  const context = useContext(HydromodelsContext);
  const { modelSchematic } = context.modelInformation;

  if (!modelSchematic) return null;
  return (
    <>
      <SectionTitle title="Model Schematic" />
      <iframe
        id="schematic"
        ref={schematicRef}
        title="schematic"
        frameBorder="0"
        src={modelSchematic}
        style={{ width: "100%", height: 480 }}
      ></iframe>
    </>
  );
};

export default ModelSchematic;
