import React, { useContext, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Box } from "@material-ui/core";
import { HydromodelsContext } from "../../contexts/HydromodelsProvider";
import theme from "../../theme";
import HeatmapDatePicker from "../HeatmapDatePicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  titleWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  pageTitle: {
    marginRight: theme.spacing(5),
  },
  textField: {
    width: 150,
    margin: theme.spacing(0, 2),
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "increment":
      return state + 1;
    case "decrement":
      return state - 1;
    case "change":
      return action.value;
    default:
      throw new Error();
  }
};

const PageTitle = (props) => {
  const [date, setDate] = useState({});
  const context = useContext(HydromodelsContext);
  const {
    filters,
    modelRunDate,
    eventsData,
    viewerType,
    handleChange,
    modelRunDateRef,
    handleViewerType,
  } = context;
  const [year, setYear] = useReducer(reducer, 2020);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <Typography
          className={classes.pageTitle}
          variant="h4"
          data-testid="page-title"
        >
          {filters.model.title}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body1"
            display="inline"
            style={{ marginRight: theme.spacing(3) }}
          >
            Run date:{" "}
            {modelRunDate &&
              modelRunDate.toLocaleString("en-US", {
                timeZone: "America/Denver",
              })}
          </Typography>
          {eventsData.length > 0 && (
            <HeatmapDatePicker
              data={eventsData}
              year={year}
              date={date}
              categories={["Moderate", "High", "Very High", "Extreme"]}
              onDecrement={() => setYear({ type: "decrement" })}
              onIncrement={() => setYear({ type: "increment" })}
              onDropdownChange={(e) =>
                setYear({ type: "change", value: e.target.value })
              }
              onDateSelect={(val) => setDate(val)}
              onSubmit={() => {
                handleChange("date", date.date);
                modelRunDateRef.current = moment(date.date).format("YYYY-MM-DD");
                handleViewerType("historical");
              }}
            />
          )}
          {viewerType === "historical" && (
            <Box ml={1}>
              <Button
                onClick={() => handleViewerType("real-time")}
                variant="contained"
              >
                Return to Real Time Viewer
              </Button>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default PageTitle;
