import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const DynamicImage = ({ src, alt }) => {
  const classes = useStyles();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (src) {
      import(`../../images/${src}`).then((img) => {
        setImage(img.default);
      });
    }
  }, [src]);

  return (
    <div className={classes.root}>{image && <img src={image} alt={alt} />}</div>
  );
};

DynamicImage.propTypes = {
  /** Alt attribute for the image if the image
   * cannot be loaded
   */
  alt: PropTypes.string,
  /**
   * Path for the image that should be dynamically
   * imported and displayed.
   */
  src: PropTypes.string,
};

export default DynamicImage;
