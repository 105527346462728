import React from "react";
import PropTypes from "prop-types";
import { makeStyles, lighten } from "@material-ui/core/styles";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import ReactTooltip from "react-tooltip";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: theme.breakpoints.values.lg,
    marginLeft: "auto",
    marginRight: "auto",
  },
  empty: {
    fill: "#555",
  },
  class1: {
    fill: lighten(theme.palette.primary.main, 0.75),
  },
  class2: {
    fill: lighten(theme.palette.primary.main, 0.5),
  },
  class3: {
    fill: lighten(theme.palette.primary.main, 0.25),
  },
  class4: {
    fill: theme.palette.primary.main,
  },
  yearSelect: {
    border: "none",
  },
}));

const getTooltipDataAttrs = (value) => {
  // Temporary hack around null value.date issue
  if (!value || !value.date) {
    return null;
  }
  // Configuration for react-tooltip
  return {
    "data-tip": `${new Date(`${value.date} 00:00:00`).toLocaleDateString()}: ${
      value.cat
    } Flooding`,
  };
};

const generateYears = () => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = currentYear; i > 2011; i--) {
    years.push(i);
  }
  return years;
};

const HeatmapDatePicker = ({
  year,
  date,
  data,
  categories,
  onSubmit,
  onDecrement,
  onIncrement,
  onDropdownChange,
  onDateSelect,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className={classes.root}>
      <Button variant="contained" onClick={() => setDialogOpen(true)}>
        Open Date Picker
      </Button>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xl"
        fullWidth
        className={classes.dialog}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" color="textSecondary">
              Historical Data Explorer
            </Typography>
            <Box mr={8} display="flex" alignItems="center">
              <IconButton onClick={onDecrement}>
                <ArrowLeft />
              </IconButton>
              <FormControl className={classes.formControl}>
                <Select
                  id="year-select"
                  value={year}
                  onChange={onDropdownChange}
                  className={classes.yearSelect}
                >
                  {generateYears().map((y) => (
                    <MenuItem key={y} value={y}>
                      {y}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton onClick={onIncrement}>
                <ArrowRight />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <CalendarHeatmap
                startDate={`${year}-01-01`}
                endDate={`${year}-12-31`}
                values={data}
                tooltipDataAttrs={getTooltipDataAttrs}
                gutterSize={2}
                onClick={(date) =>
                  date !== null ? onDateSelect(date) : () => {}
                }
                classForValue={(value) => {
                  if (!value) {
                    return classes.empty;
                  }

                  const { cat } = value;

                  // console.log(value, categories);

                  if (cat === categories[0]) {
                    return classes.class1;
                  } else if (cat === categories[1]) {
                    return classes.class2;
                  } else if (cat === categories[2]) {
                    return classes.class3;
                  } else if (cat === categories[3]) {
                    return classes.class4;
                  }
                }}
              />
              <ReactTooltip />
              <Box mt={2} display="flex">
                {categories.map((cat, index) => (
                  <Box
                    key={cat}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mr={2}
                  >
                    <Box
                      width={30}
                      height={15}
                      bgcolor={
                        index + 1 !== categories.length
                          ? lighten(
                              theme.palette.primary.main,
                              (categories.length - (index + 1)) /
                                categories.length
                            )
                          : theme.palette.primary.main
                      }
                    ></Box>
                    <Typography variant="caption">{cat}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              {typeof date.date !== "undefined" ? (
                <>
                  <Typography color="textSecondary" align="center" gutterBottom>
                    Peak Event Time for {date.date}
                  </Typography>
                  <Typography variant="body1" align="center">
                    {date.time}
                  </Typography>
                </>
              ) : (
                <Typography color="textSecondary" align="center" gutterBottom>
                  No Date Selected
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setDialogOpen(false);
              onSubmit();
            }}
          >
            Submit
          </Button>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

HeatmapDatePicker.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.array.isRequired,
  date: PropTypes.shape({
    date: PropTypes.string,
    cat: PropTypes.string,
    time: PropTypes.string,
  }),
  onDateSelect: PropTypes.func,
  onDecrement: PropTypes.func,
  onDropdownChange: PropTypes.func,
  onIncrement: PropTypes.func,
  year: PropTypes.number,
};

export default HeatmapDatePicker;
