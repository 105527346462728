import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import Hydromodel from "./pages/Hydromodel";
import { HydromodelsProvider } from "./contexts/HydromodelsProvider";

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            render={() => <Redirect to="boulder-creek" />}
          />
          <Route
            path="/:model"
            exact
            render={(props) => (
              <HydromodelsProvider>
                <Hydromodel />
              </HydromodelsProvider>
            )}
          />
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

export default App;
