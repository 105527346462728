import React, {useState, useEffect, useRef} from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import useFetchData from "../hooks/useFetchData";
import config from "./config";
import moment from "moment";

// Create a context that will be used to share global state
// throughout the daily ops page
export const HydromodelsContext = React.createContext({
  viewerType: "",
  filters: {},
  modelInformation: {},
  gages: {},
  eventsData: [],
  modelOutFlowsData: [],
  precipData: [],
  modelRunDate: null,
  handleChange: () => {},
  handleViewerType: () => {},
  modelRunDateRef: null
});

// create a context provider to handle global state
export const HydromodelsProvider = (props) => {
  const [viewerType, setViewerType] = useState("real-time");
  let history = useHistory();
  let match = useRouteMatch();
  const [modelRunDate, setModelRunDate] = useState(null);
  const [gages, setGages] = useState([]);

  const modelRunDateRef = useRef(moment().format("YYYY-MM-DD"));

  const [filters, setFilters] = useState({
    //nothing actually references filters.date, it is only used to force a rerender
    //when the modelRunDateRef.current changes from the UI submit button. The useRef is used
    //so the mapbox .onClick handler gets the .current reference, instead of the literal value
    //that is assigned when the click event is created
    date: moment().format("YYYY-MM-DD"),
    time: "00:05:00",
    designPoint: {
      model: match.params.model,
      index: config[match.params.model].defaultDesignPoint,
    },
    model: config[match.params.model],
  });

  const [modelInformation, setModelInformation] = useState({
    modelBackground: config[match.params.model].modelBackground,
    modelSubBasins: config[match.params.model].modelSubBasins,
    modelMethods: config[match.params.model].modelMethods,
    modelInputs: config[match.params.model].modelInputs,
    modelSchematic: config[match.params.model].modelSchematic,
  });

  const setModelOutFlowsEndpoint = (type, designPoint, date, time) => {
    if (type === "real-time") {
      return `model-outflows/${designPoint}/${date}/${time}`;
    } else if (type === "historical") {
      return `historical-model-outflows/${designPoint}/${date}`;
    }
  };

  const setPrecipEndpoint = (type, model, date, time) => {
    if (type === "real-time") {
      return `precip/${model}/${date}/${time}`;
    } else if (type === "historical") {
      return `historical-precip/${model}/${date}`;
    }
  };

  /**
   * Retrieve a list of events for which there is
   * "interesting" data.
   * Used to populate the date picker
   * Retrieve data whenever the selected model changes
   */
  const [eventsData] = useFetchData(
    `events/${config[match.params.model].ndx}`,
    [match.params.model]
  );

  // Retrieve the models outflow data from the database
  // the data will be re-fetched anytime the design point,
  // date, or time change
  const [modelOutFlowsData] = useFetchData(
    setModelOutFlowsEndpoint(
      viewerType,
      filters.designPoint.index,
        modelRunDateRef.current,
      filters.time
    ),
    [viewerType, filters.designPoint.index, filters.date, filters.time]
  );

  // Retrieve the models outflow data from the database
  // the data will be re-fetched anytime the model,
  // date, or time change
  const [precipData] = useFetchData(
    setPrecipEndpoint(viewerType, filters.model.id, modelRunDateRef.current, filters.time),
    [viewerType, filters.model, filters.date, filters.time]
  );

  /**
   * Handler for updating the active viewerType
   * i.e. real-time or historical
   * @param {string} val
   */
  const handleViewerType = (val) => {
    setViewerType(val);
  };

  /**
   * Handler for updating the filters object
   * @param {string} name filters property to update
   * @param {*} value
   */
  const handleChange = (name, value) => {
    setFilters(() => {
      const newFilters = { ...filters };
      newFilters[name] = value;
      return newFilters;
    });
  };

  /**
   * Logic used to update, display, and format
   * the last model run date
   */
  useEffect(() => {
    if (modelOutFlowsData.length > 0 && viewerType === "real-time") {
      const filtered = modelOutFlowsData.filter((d) => d.timestep_min === 0)[0];
      setModelRunDate(
        moment(filtered.ts_timestamp).format("M/DD/YYYY, h:mm A")
      );
    } else if (modelOutFlowsData.length > 0 && viewerType === "historical") {
      const filtered = modelOutFlowsData[0];
      setModelRunDate(
        moment(filtered.ts_timestamp).format("M/DD/YYYY, h:mm A")
      );
    }
  }, [modelOutFlowsData]); //eslint-disable-line

  /**
   * Logic used to update the model metadata information
   * whenever the model changes
   */
  useEffect(() => {
    setModelInformation({
      modelBackground: config[match.params.model].modelBackground,
      modelSubBasins: config[match.params.model].modelSubBasins,
      modelMethods: config[match.params.model].modelMethods,
      modelInputs: config[match.params.model].modelInputs,
      modelSchematic: config[match.params.model].modelSchematic,
    });
    setGages(config[match.params.model].gages);
    setViewerType("real-time");
  }, [match]);

  /**
   * Logic used to update the history whenever the selected
   * design point changes
   */
  useEffect(() => {
    const { pathname } = window.location;
    if (!pathname.includes(filters.designPoint.model)) {
      history.push(filters.designPoint.model);
    }
  }, [filters.designPoint]); //eslint-disable-line

  /**
   * Update the design point and model properties on the filters
   * object whenever the page changes
   */
  useEffect(() => {
    const activeModel = match.params.model;
    const designPoint = {
      model: activeModel,
      index:
        config[activeModel].activeDesignPoint ||
        config[activeModel].defaultDesignPoint,
    };
    const model = { ...config[activeModel] };
    setFilters((prevState) => {
      const newFilters = { ...prevState };
      newFilters.designPoint = designPoint;
      newFilters.model = model;
      return newFilters;
    });
  }, [match]);

  return (
    <HydromodelsContext.Provider
      value={{
        viewerType,
        filters,
        modelInformation,
        gages,
        eventsData,
        modelOutFlowsData,
        precipData,
        modelRunDate,
        handleChange,
        handleViewerType,
        modelRunDateRef
      }}
    >
      {props.children}
    </HydromodelsContext.Provider>
  );
};
