import { createMuiTheme } from '@material-ui/core/styles';
import { blue, green } from '@material-ui/core/colors';

// This allows us to wrap the entire application in our custom theme
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: blue,
    secondary: green,
  },
  typography: {
    useNextVariants: true,
  }
});

export default theme;