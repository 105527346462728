import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../images/logo.svg";

const ButtonLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    // backgroundColor: theme.palette.secondary.main,
    padding: `20px 16px`,
    ...theme.mixins.toolbar,
  },
  nav: {
    color: `#ffffff`,
  },
  navIcon: {
    color: `#ffffff`,
  },
  navText: {
    "& span": {
      fontSize: `18px!important`,
    },
  },
  activeBtn: {
    backgroundColor: theme.palette.primary.main,
  },
  siteTitle: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 0),
  },
  logo: {
    marginRight: theme.spacing(2),
    maxHeight: 40,
  },
}));

const TopNav = ({ history, links = [], container }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMenuToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const setActive = (route) => {
    if (history.location.pathname.includes(route) && route !== "/") {
      return true;
    } else if (route === "/" && history.location.pathname === "/") {
      return true;
    }
    return false;
  };

  const setActiveClass = (route) => {
    if (history.location.pathname.includes(route) && route !== "/") {
      return classes.activeBtn;
    } else if (route === "/" && history.location.pathname === "/") {
      return classes.activeBtn;
    }
    return "";
  };

  // function for navigating to a specific page in the app
  const goTo = (route) => {
    history.push(`/${route}`);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Toolbar className={classes.root}>
          <Hidden mdUp implementation="css">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleMenuToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.siteTitle}>
            <img className={classes.logo} src={logo} alt="UDFCD Logo" />
            <Typography variant="h5">Rainfall Runoff Models</Typography>
          </div>
          <Hidden smDown implementation="css">
            <div data-testid="links">
              {links.map((link) => (
                <Button
                  key={link.title}
                  color="inherit"
                  component={ButtonLink}
                  to={link.path}
                  className={setActiveClass(link.path)}
                >
                  {link.title}
                </Button>
              ))}
            </div>
          </Hidden>
        </Toolbar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleMenuToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.toolbar}>
                <Typography variant="h5">UDFCD Hydromodels</Typography>
              </div>
              <List className={classes.nav}>
                {links.map((link) => (
                  <ListItem
                    key={link.path}
                    button
                    onClick={() => goTo(link.path)}
                    selected={setActive(link.path)}
                  >
                    <ListItemText
                      primary={link.title}
                      className={classes.navText}
                    />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Hidden>
        </nav>
      </AppBar>
    </div>
  );
};

TopNav.propTypes = {
  history: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TopNav;
